<template>
  <div class="news">
    <div class="main_top">
      <!-- <div style="display: flex">
        <div class="block" style="margin-right: 20px">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <el-input
          v-model="selectTxt"
          placeholder="请输入信息搜索"
          style="width: 300px"
        ></el-input>
        <el-button
          slot="reference"
          @click="departSelect"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div> -->
      <div style="display: flex">
        <el-button class="style_button" type="text" @click="handleAdd()"
          >添加</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      tooltip-effect="dark"
    >
      <el-table-column label="序号" width="100" type="index"> </el-table-column>
      <el-table-column label="标题" show-overflow-tooltip>
        <template slot-scope="scope">
          <p>{{ scope.row.title }}</p>
        </template>
      </el-table-column>

      <el-table-column label="发布时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <p>{{ scope.row.date }}</p>
        </template>
      </el-table-column>

      <el-table-column label="发布人" show-overflow-tooltip>
        <template slot-scope="scope">
          <p>{{ scope.row.username }}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row.id,scope.row)" type="text" size="small" icon="el-icon-edit">修改</el-button>
          <el-button type="text" size="small" icon="el-icon-delete" @click="handleRemove(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getMessage,delMessageOne} from '@/utils/api'
import { Message } from 'element-ui';
export default {
  inject: ["reload"],
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      perList:[],
      tableData: [], //干警列表
      total: 0, //总条数
      page: 1, //页数
      currentPage: 1, //当前页数
      selectTxt: "", //搜索当前项
      date: "", //时间
    };
  },
  mounted() {
    this.getmesslist();
    this.perList = JSON.parse(window.localStorage.getItem("permissionid"));
    // console.log(this.perList);
  },
  methods: {
    getmesslist(){
      getMessage({
        type:1
      }).then(res =>{
        // console.log(res);
        this.tableData = res.data.data
      })
    },
    //添加
    handleAdd() {
      this.$router.push('/Information/publish?type=news')
    },
    //修改
    handleEdit(value,row) {
      this.$router.push(`./publish?type=news&id=${value}`);
    },
    //删除
    handleRemove(id) {
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
          delMessageOne({
            msgId:id
          }).then(res =>{
            if(res.data.code == 200){
              Message.success("删除成功！");
              this.reload();
            }
          });
        })
        .catch(() => {
          Message.info("已取消删除!");
        });
    },
    //搜索
    departSelect() {
      var content = this.selectTxt;
      var endTime = null;
      var startTime = null;
      // console.log(this.data,'data')
      if (this.date !== '' && this.date !== null) {
        // console.log(this.date,'进来了')
        endTime = this.date[1] ;
        startTime = this.date[0];
        endTime=endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate();
        startTime=startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate();
      }else{
       endTime = null;
       startTime = null;
      }
      // console.log(content,endTime,startTime)
      searchInstructions(content,endTime,startTime).then((data) => {
        // console.log(data)
        if (data.data.data == null) {
          Message.warning("查询内容不存在！");
        } else {
          this.tableData = data.data.data;
          this.total = data.data.data.total;
          this.currentPage = data.data.data.current;
        }
      });
    },

    //分页器
    handleCurrentChange(val) {
      this.page = val;
      if (this.selectTxt !== "") {
        this.departSelect();
        return;
      }
      this._getAllInstructions();
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
  },
  computed: {
    sexyOp() {
      return this.form.gender == "0" ? "男" : "女";
    },
  },
};
</script>

<style lang='less' >
.news{
  width: auto;
  min-height: 95%;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
  border-radius: 4px;
  padding: 24px;
  // box-sizing: border-box;
  position: relative;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-dialog {
    margin: 300px auto !important;
    width: 30% !important;
    min-width: 570px;
  }

  .el-dialog__header {
    border-bottom: 1px solid #e9e6e6;
  }
  .el-dialog__footer {
    border-top: 1px solid #e9e6e6;
  }
  .dialog_title {
    > img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    > span {
      font-size: 13px;
      font-weight: 400;
      color: #2d8cf0;
    }
  }
  .form_label {
    > span {
      color: red;
      margin-right: 5px;
    }
  }
  .el-form-item__label {
    text-align: left;
    .form_label {
      font-size: 13px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item .el-input__inner {
    width: 275px;
    height: 23px;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
  }
  .style_button_del {
    background: #ff0012;
    margin-left: 13px;
  }
  .el-table td,
  .el-table th {
    text-align: center;
    padding: 8px 0;
  }
  .el-table {
    margin-top: 15px;
    .editColor {
      background: #3a8fea;
    }
  }
  .el-button--danger {
    background: #ff5200 !important;
  }
  .el-pagination {
    // position: absolute;
    // bottom: 34px;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
.style_button_Sel {
  margin-left: 10px;
}
</style>